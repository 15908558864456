import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alert",
      "style": {
        "position": "relative"
      }
    }}>{`Alert`}</h1>
    <p>{`Alertas são componentes que usualmente aparecem na parte superior da tela, e fornecem informações críticas sobre perda de dados, funcionalidades, erros, etc.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Evite apresentar mais de uma notificação por vez e/ou utilizar mensagens muito extensas.`}</li>
      <li parentName="ul">{`Utilize alertas para apresentar informações que precisam ser visualizadas imediatamente e que requerem a atenção do usuário.`}</li>
      <li parentName="ul">{`Apresente mensagens claras e que indiquem soluções ou próximos passos para o usuário.`}</li>
      <li parentName="ul">{`Evite apresentar mensagens com códigos técnicos de erro que não ajudam o usuário a resolver o problema.`}</li>
      <li parentName="ul">{`Apresente mensagens específicas para cada situação ao invés de utilizar mensagens genéricas.`}</li>
      <li parentName="ul">{`Sempre que possível assuma a responsabilidade ao invés de culpar o usuário. Exemplo: "Estamos com problemas ao conectar." ao invés de "Você está com problemas na conexão."`}</li>
    </ul>
    <h2 {...{
      "id": "tipos-de-alertas",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos de alertas`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Informação</th>
      <td>
        Cor azul. A notificação padrão é normalmente utilizada para dar informações de menor importância ou que não
        requerem ações do usuário. Geralmente não são em resposta a alguma ação do usuário. (Ex: "Você está logado com o
        usuário João da Silva. Deslogar.")
      </td>
    </tr>
    <tr>
      <th>Perigo</th>
      <td>
        Cor vermelha. Indica ao usuário que algum processo ou tarefa não pode ser completada. Mensagens de erro devem
        ser claras e objetivas, evitando apresentar códigos de erro técnicos. Sempre que possível apresente uma solução
        ou link indicando o próximo passo. (Ex.: "Não foi possível adicionar o último item. Por favor, atualize a
        página.")
      </td>
    </tr>
    <tr>
      <th>Alerta</th>
      <td>
        Cor laranja. São usualmente antecipações de mudanças e ajudam a prevenir erros. Comunique claramente o que vai
        acontecer caso o usuário prossiga com a ação e forneça uma alternativa quando possível. (Ex.: "Sua licença está
        prestes a expirar. Por favor, renove a licença até 05 de Agosto de 2019.")
      </td>
    </tr>
    <tr>
      <th>Sucesso</th>
      <td>
        Cor verde. Fornece um feedback positivo às ações do usuário. (Ex.: "Profissional cadastrado com sucesso.")
      </td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "exemplos",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplos`}</h2>
    <Demo src='pages/components/alert/AlertDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      